import React from 'react'
import Helmet from 'react-helmet'
import { withPrefix } from 'gatsby'
import ConditionalLayout from '../components/ConditionalLayout'
import ServiceContainer from '../components/ServiceContainer'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

import { LargeP, DownloadButton, FlexList, FlexItem } from '../elements'

const OfficeCareersPage = ({ data }) => {
  const postNode = {
    title: `Office Careers - ${config.siteTitle}`,
  }

  return (
    <ConditionalLayout>
      <Helmet>
        <title>{`Office Careers - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <ServiceContainer>
        <PageTitle>Office Careers</PageTitle>
        <LargeP>
          D&D was founded in the small farming community of Gooding,
          Idaho–placing its roots and ethics with the families and hands that
          feed America.
        </LargeP>
        <p>
          Come join our growing team of office personal. We are always on the
          look out for office professionals that want to work hard and have fun
          doing it. The transportation industry is a fast moving exciting place
          to work and we carry that attitude into every workday. Enthusiasm is
          key to success.
        </p>

        <h4>Logistics Agent</h4>
        <p>
          We are currently looking for aggressive, motivated and experienced
          logistics agents who posses’ strong skills and knowledge in the
          movement of refrigerated and dry freight. If you are an individual who
          strives to achieve outstanding results, if you have a strong work
          ethic, and work with honesty and integrity, we are looking for you. We
          specialize in the nationwide transportation of refrigerated and dry
          goods for food-grade products, with a record 99 percent on-time
          delivery. We have the resources and tools you need to succeed as an
          agent.
        </p>

        <DownloadButton
          style={{ marginBottom: '2rem' }}
          rel="noopener noreferrer"
          href={withPrefix('/DDTSI-EmployeeApplication-20141211.pdf')}
          target="_blank"
        >
          Download the Employment Application&nbsp;
          <i className="material-icons">get_app</i>
        </DownloadButton>

        <h4>Customer Service Representative</h4>
        <p>
          D&D Transportation Services Inc. is a nationwide transportation
          service. We are currently seeking a Customer Service Person to execute
          our day-to-day load plan. This individual will be responsible for
          identifying and securing capacity to match load opportunities,
          creating low cost solutions, generating superior earning, and revenue
          growth.
        </p>
        <h5>Duties</h5>
        <ul>
          <li>
            Communicate effectively with customers, vendors, sales
            representatives and internal associates.
          </li>
          <li>Meet or exceed total service needs of the customer.</li>
          <li>
            Monitor and trace orders and equipment and follow up on findings.
          </li>
          <li>
            Solicit freight or identify capacity alternative options as needed.
          </li>
          <li>Handle spot pricing negotiations with vendors and customers.</li>
          <li>
            Anticipate problems and be pro-active rather than re-active in
            problem solving.
          </li>
          <li>Support customers needs in the market place.</li>
          <li>Responsible for new business development.</li>
        </ul>
        <DownloadButton
          style={{ marginBottom: '2rem' }}
          rel="noopener noreferrer"
          href={withPrefix('/DDTSI-EmployeeApplication-20141211.pdf')}
          target="_blank"
        >
          Download the Employment Application&nbsp;
          <i className="material-icons">get_app</i>
        </DownloadButton>
      </ServiceContainer>
    </ConditionalLayout>
  )
}

export default OfficeCareersPage
